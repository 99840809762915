import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  Keyboard,
} from "swiper/core";
import "swiper/swiper-bundle.min.css";
import LUX from "../assets/images/The Lux Dubai text n fonts UI-UX-12.webp"
import LUX2 from "../assets/images/The Lux Dubai text n fonts UI-UX-13.webp"
import LUX3 from "../assets/images/The Lux Dubai text n fonts UI-UX-14.webp"
import "./imageCard.css"
SwiperCore.use([Navigation, Autoplay, Pagination, Keyboard]);

function Crousel() {
  const swiperOptions = {
    spaceBetween: 40, // Adjust the space between slides here
    speed: 1000,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
    mousewheel: false,
    keyboard: true,
    autoplay: {
      delay: 5000,
    },
    loop: true,
    breakpoints: {
  0: {
    slidesPerView: 1,
    },
      
  400: {
    slidesPerView: 1.2,
  },
  480: {
    slidesPerView: 1.5,
  },
  625: {
    slidesPerView: 1.5,
  },
  768: {
    slidesPerView: 2,
  },
  991: {
    slidesPerView: 3.2,
  },
  1150: {
    slidesPerView: 3.3,
  },
  1200: {
    slidesPerView: 4,
  },
}
  };


  const data = [
 
    {
    image: LUX,
    Title: 'LUXARY',
    subTitle: 'PENTHOUSE',
    
    },
    {
    image: LUX2,
    Title: 'LUXARY',
    subTitle: 'PROPERTIES',    },
    {
    image: LUX3,
    Title: 'MARINA',
    subTitle: 'LIVING',

    },
        {
    image: LUX,
    Title: 'LUXARY',
    subTitle: 'PENTHOUSE',
    
    },
    {
    image: LUX3,
    Title: 'MARINA',
    subTitle: 'LIVING',
    },

  
  ];

  return (
    <>
    
      <div className="swiper-buttons">
        <button className="swiper-prev">
          {" "}
          <FontAwesomeIcon icon={faArrowUp} className='footer-button-arrow' style={{rotate:"295deg"}} />  Previous
        </button>
        <button className="swiper-next">
          {" "}
         Next <FontAwesomeIcon icon={faArrowUp} className='footer-button-arrow' style={{rotate:"45deg"}}/>
        </button>
      </div>
      <Swiper {...swiperOptions} className="swiper-wrapper-Award swiper-wrapper">
     
        {data.map((property, index) => (
         <SwiperSlide>
        <div key={index} className="card-img">
          <img src={property.image} alt="Avatar" style={{ width: "100%" }} />
          <div className="container-img">
            <h4><b>{property.Title}</b></h4>
            <p>{property.subTitle}</p>
          </div>
            </div>
        </SwiperSlide>

      ))}
      </Swiper>
      <div className="swiper-pagination"></div>
</>
      );
}

export default Crousel;
