import './assets/style/style.css';
import Index from "./pages/Index"

function App() {
  return (
    <div>
      <Index />
    </div>
  );
}

export default App;
