import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwiperCore, {
  Navigation,
  Autoplay,
  Pagination,
  Keyboard,
} from "swiper/core";
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons'; // Assuming you want to use the Facebook brand icon
import "swiper/swiper-bundle.min.css";
import "../components/card.css"
import Aziz from "../assets/images/The Lux Dubai text n fonts UI-UX-02.webp"
import Aziz2 from "../assets/images/The Lux Dubai text n fonts UI-UX-03.png"
import Aziz3 from "../assets/images/The Lux Dubai text n fonts UI-UX-04.webp"
// import Aziz from "../assets/images/The Lux Dubai text n fonts UI-UX-02.png"
// import Aziz from "../assets/images/The Lux Dubai text n fonts UI-UX-02.png"
import { faLocationDot, faPhone,faEnvelope,faBed,faBath,faMapLocationDot } from '@fortawesome/free-solid-svg-icons';


SwiperCore.use([Navigation, Autoplay, Pagination, Keyboard]);

function Crousel() {
  const swiperOptions = {
    spaceBetween: 40, // Adjust the space between slides here
    speed: 1000,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
    mousewheel: false,
    keyboard: true,
    autoplay: {
      delay: 5000,
    },
    loop: true,
    breakpoints: {
  0: {
    slidesPerView: 1,
    },
      
  400: {
    slidesPerView: 1.2,
  },
  480: {
    slidesPerView: 1.5,
  },
  625: {
    slidesPerView: 1.5,
  },
  768: {
    slidesPerView: 2,
  },
  991: {
    slidesPerView: 3.2,
  },
  1150: {
    slidesPerView: 3.3,
  },
  1200: {
    slidesPerView: 4,
  },
}
  };

  const data = [
    {
    image: Aziz,
    Title: 'AZIZ VENICE',
    Location: "Madinat ul Aura",
    bed: 4,
    bath: 5,
      sq: "7235",
        price: "AED 138,000,000" 

  },
    {
    image: Aziz2,
    Title: 'XXII CARAT',
    Location: "The Cresent",
    bed: 4,
    bath: 5,
      sq: "235",
        price: "AED 140,000,000" 

    },
    {
    image: Aziz3,
    Title: 'BAYAN TREE RESIDENCY',
    Location: "Jumerah Lakes",
    bed: 4,
    bath: 5,
      sq: "8735",
        price: "AED 48,000,000" 

    },
    {
    image: Aziz,
    Title: 'DUBAI VENICE',
    Location: "Madinat ul Aura",
    bed: 4,
    bath: 5,
      sq: "9035",
        price: "AED 138,000,000" 

    },
    {
    image: Aziz2,
    Title: 'BURJ UL ARAB',
    Location: "Burj ul Madina",
    bed: 4,
    bath: 5,
      sq: "5635",
    price: "AED 138,000,000" 
    },
  
  ];

  return (
    <>
    
      <div className="swiper-buttons">
        <button className="swiper-prev">
          {" "}
          <FontAwesomeIcon icon={faArrowUp} className='footer-button-arrow' style={{rotate:"295deg"}} />  Previous
        </button>
        
        <button className="swiper-next">
          {" "}
         Next <FontAwesomeIcon icon={faArrowUp} className='footer-button-arrow' style={{rotate:"45deg"}}/>
        </button>
      </div>
      <Swiper {...swiperOptions} className="swiper-wrapper-Award swiper-wrapper">
     
        {data.map((item, index) => (
                 <SwiperSlide>
        <div className="card" key={index}>
          <img className="card-img-top" src={item.image} alt="Property" />
          <div className="card-body">
            <h3 className="card-title">{item.Title}</h3>
            <p className="card-text"><FontAwesomeIcon icon={faLocationDot} /> {item.Location}</p>
            <div className='card-content-upper'>
              <div className='card-rooms'>
                <p style={{width:"40px"}}><FontAwesomeIcon icon={faBed} /> {item.bed}</p>
              </div>
              <div className='card-persons'>
                <p style={{width:"40px"}}><FontAwesomeIcon icon={faBath} /> {item.bath}</p>
              </div>
              <div className='card-sq-ft'>
                <p style={{width:"85px"}}><FontAwesomeIcon icon={faMapLocationDot} /> {item.sq} SQ/FT</p>
                {/* <FontAwesomeIcon icon={faMapLocationDot} /> {item.sq} sqr/fts */}
              </div>
            </div>
            <div className='card-price'>
              <p>{item.price}</p>
            </div>
            <div className='card-cons-bottom'>
              <div className='call'>
                <FontAwesomeIcon icon={faPhone} className="phone-icon"/>
              </div>
              <div className='mesg'>
                <FontAwesomeIcon icon={faEnvelope} className="envelop-icon"/>
              </div>
              <div className='whatsapp'>
                <a href="/"><FontAwesomeIcon icon={faWhatsapp} className="whatsapp" /></a>
              </div>
            </div>
          </div>
          </div>
                  </SwiperSlide>

      ))}
       
      </Swiper> 
      <div className="swiper-pagination"></div>
</>
      );
}

export default Crousel;


